import request from '@/utils/request';

const PATH = '/stay/room';

export const getStayRoomList = (params: any) => request({
  url: PATH,
  method: 'get',
  params,
});

export const getStayRoom = (idx: any, params: any) => request({
  url: `${PATH}/${idx}`,
  method: 'get',
  params,
});

export const getStayRoomForm = (idx: any, params: any) => request({
  url: `${PATH}/${idx}/form`,
  method: 'get',
  params,
});

export const reserveStayRoom = (data: any) => request({
  url: `${PATH}/reserve`,
  method: 'post',
  data,
});
